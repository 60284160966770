@import "./vars.scss";

.container {
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    max-width: nth($containerWidths, $breakpointCount+1);

    &.align-top {
        align-self: flex-start;
    }
}

@for $i from $breakpointCount to 0 {
    @media screen and (max-width: nth($breakpoints,$i)) {
        .container{
            max-width: nth($containerWidths, $i);
        }
    }
}

.flex1 {
  flex: 1;
}

.flex {
  display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.row {
  display: flex;
  flex-direction: row;

  @media (max-width: $breakpoint3) {
    & {
        flex-wrap: wrap;
    }
  }
}

.col {
  display: flex;
  flex-direction: column;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    align-content: flex-start
}

.grid > div {
    margin: 20px;
    padding: 10px;
    flex: 0 0 275px;
}

.grid > div > p {
    line-height: 30px;
}

.center-items {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex1 {
    flex: 1;
}
