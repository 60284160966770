.progress-bar{
    position: relative;
    border-radius: 10px;
    height:20px;
    margin-bottom: 20px;
    background-color: #ccc;

    >div{
        position: absolute;
        border-radius: 10px;
        height:100%;
        background-color: blue;
        transition: width ease-in-out 1.3s;
    }
}