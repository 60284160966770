
$space0: 0;
$space1: 10px;
$space2: 20px;
$space3: 30px;
$space4: 50px;
$space5: 80px;
$space6: 100px;

$spaces: (
    "0": $space0,
    "1": $space1,
    "2": $space2,
    "3": $space3,
    "4": $space4,
    "5": $space5,
    "6": $space6
);

$white:#ffffff;
$offWhite: #f8f7fc;
$black:#000000;
$gray:#5C5C5C;
$lightGray: #dddddd;
$accent1:#899878;
$accent2:#E6AF2E;
$mapBorder:#B80000;

$starYellow:#FFC107;

$lightShadow: rgba($black, 0.08);
$shadow: rgba($black, 0.15);

$primaryColor:$accent2;
$muted:#828282;
$foreground: $black;
$borderRadius:20px;

$buttonColor:#cccccc;

// Breakpoint values should match defaultBreakPoints in lib/shared-hooks.ts
$breakpoint1:576px;
$breakpoint2:768px;
$breakpoint3:992px;
$breakpoint4:1200px;

$breakpoints: (
    $breakpoint1,
    $breakpoint2,
    $breakpoint3,
    $breakpoint4,
);

$breakpointCount:length($breakpoints);

$containerWidth1:100%;
$containerWidth2:540px;
$containerWidth3:720px;
$containerWidth4:960px;
$containerWidth5:1140px;

$containerWidths: (
    $containerWidth1,
    $containerWidth2,
    $containerWidth3,
    $containerWidth4,
    $containerWidth5
);

$fontFamily:Roboto;
$fontFamily2:Montserrat;
$fontFamily3:'Balsamiq Sans', cursive;
$headerFontFamily:$fontFamily2;
$logoFontFamily: $fontFamily3;

$navBarHeight:114px;
$navBarHeightMobile:80px;

$trans:0.3s ease-in-out;
$scale: scale(1.03);

$cardShadow: 0 4px 12px $lightShadow
