.upload-item{
    display: flex;
    flex-direction: column;

    background-color: #f5f5f5;
    padding:0 20px;
    border-radius: 20px;

    $containerSize:280px;
    margin-bottom: 40px;

    .upload-container{
        background-color: #000;
        padding:20px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        color:#fff;
        border-radius: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        video,img{
            width:$containerSize;
            height:$containerSize;
            object-fit: contain;
            margin-top: 20px;
        }
    }

    .header-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        input{
            width:30px;
            font-size: 22px;
            text-align: center;
        }
    }
}