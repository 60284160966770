@import "../style/vars.scss";

.link{
    text-decoration: underline;
    cursor: pointer;

    &.min{
        text-decoration: none;
    }
}
