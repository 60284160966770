.user-selector{
    ul{
        list-style: none;
        margin:20px 0;
        padding: 0;
        li{
            margin:10px 0;
            width:300px;
            max-width: 100%;
        }
    }
    button{
        display: flex;
        align-items: center;
        width:100%;
    }
    img{
        width:60px;
        height:60px;
        border-radius: 30px;
        margin-right: 10px;
    }
}