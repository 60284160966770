.screen{
    display: flex;
    flex-direction: column;
    position:absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    &.trans-active{
        overflow-y: hidden;
    }
}

$transTime:300ms;

@keyframes transFadeIn{
    0%{opacity:0;}
    100%{opacity:1;}
}
@keyframes transFadeOut{
    0%{opacity:1;}
    100%{opacity:0;}
}

.trans-fade-in {
    animation: transFadeIn;
    animation-duration: $transTime;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}
.trans-fade-out {
    animation: transFadeOut;
    animation-duration: $transTime;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}


@keyframes transSlideIn{
    0%{transform:translateX(100%)}
    100%{transform:translateX(0%)}
}
@keyframes transSlideOut{
    0%{transform:translateX(0%)}
    100%{transform:translateX(-100%)}
}

@keyframes transSlideInRev{
    0%{transform:translateX(-100%)}
    100%{transform:translateX(0%)}
}
@keyframes transSlideOutRev{
    0%{transform:translateX(0%)}
    100%{transform:translateX(100%)}
}

.trans-slide-in.trans-push {
    animation: transSlideIn;
    animation-duration: $transTime;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}
.trans-slide-out.trans-push {
    animation: transSlideOut;
    animation-duration: $transTime;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

.trans-slide-in.trans-pop {
    animation: transSlideInRev;
    animation-duration: $transTime;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}
.trans-slide-out.trans-pop {
    animation: transSlideOutRev;
    animation-duration: $transTime;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}
