.uploader{

    &.uploading .uploader-content{
        opacity: 0.7;
    }

    .user-info{
        display: flex;
        align-items: center;
        margin-bottom:20px;

        img{
            width:85px;
            height:85px;
            border-radius: 50px;
            margin-right: 10px;
        }
    }
}