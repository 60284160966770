@import "./vars.scss";

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

body, button {
  font-family:
    $fontFamily, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6 {
    font-family: $headerFontFamily;
}
h1{
    font-size: 3em;
    font-weight: 700;
}

h2 {
    font-size: 2.5em;
}

h3{
    font-size: 2em;
}

h4{
    font-size: 1.6em;
}

h5{
    font-size: 1.3em;
}

h6{
    font-size: 1em;
}

a {
    color: inherit;
}

input{
  padding:10px;
  border-radius: 10px;
  border-color: #ccc;
  border-style: solid;
  border-width: 2px;
}
button{
  padding:15px 20px;
  border-radius: 10px;
  border-color: #999;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  &.primary{
    background-color: rgb(40, 36, 255);
    color:#fff;
    border-width: 0;
    font-weight: bold;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  display: flex;
}

.link-text {
    color: $accent1;
    transition: $trans;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 600;

    &.inline {
        display: inline-flex;
    }

    &.black {
        color: $black;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    &:hover{
        color: $accent2;
    }
}

.logo-font {
    font-family: $fontFamily3;
}

.text-right{
    text-align: right;
}

.text-left{
    text-align: left;
}

.text-center{
    text-align: center;
}

.text-bold {
    font-weight: 700;
}

.text-normal {
    font-weight: 500;
}

.text-light {
    font-weight: 300;
}

@each $n, $s in $spaces {
  // Padding
  .p#{$n} {
    padding: #{$s};
  }
  .ph#{$n} {
    padding-left: #{$s};
    padding-right: #{$s};
  }
  .pv#{$n} {
    padding-top: #{$s};
    padding-bottom: #{$s};
  }
  .pl#{$n} {
    padding-left: #{$s};
  }
  .pr#{$n} {
    padding-right: #{$s};
  }
  .pt#{$n} {
    padding-top: #{$s};
  }
  .pb#{$n} {
    padding-bottom: #{$s};
  }

  // Margin
  .m#{$n} {
    margin: #{$s};
  }
  .mh#{$n} {
    margin-left: #{$s};
    margin-right: #{$s};
  }
  .mv#{$n} {
    margin-top: #{$s};
    margin-bottom: #{$s};
  }
  .ml#{$n} {
    margin-left: #{$s};
  }
  .mr#{$n} {
    margin-right: #{$s};
  }
  .mt#{$n} {
    margin-top: #{$s};
  }
  .mb#{$n} {
    margin-bottom: #{$s};
  }
}

.light {
    color:$white;
}

.dark {
    color:$black;
}

.relative {
  position: relative;
}

.abs {
    position: absolute;
}

.abs-fill {
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.h-scroll {
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch;
}

.shadow-md{
    box-shadow: 0 4px 12px $lightShadow;
}
