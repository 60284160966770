$cellPadding:10px;
$cColor:#f5f5f5;

.admin-screen{
    margin: 30px 0;
}

.admin-title{
    font-weight: normal;
}

.admin-grid{
    background-color: $cColor;
    border-radius: 6px;
    display:grid;
    gap:5px;
    padding:$cellPadding;

    &.admin-grid-list > div{
        max-height: 50px;
        overflow: hidden;
    }

    .admin-grid-label-cell{
        flex-direction: column;
        justify-content: center;
    }

    > div{

        display: flex;
        background-color: #fff;
        border-radius: 6px;
        padding:$cellPadding;

        &.admin-grid-header{
            font-weight: bold;
            background-color: #00000000;
        }
    }

    .center{
        justify-content: center;
    }
}

.admin-textarea{
    height:100px;
    flex:1;
    margin:-$cellPadding;
    padding:$cellPadding;
    border:none;
    background: none;
}

.admin-input{
    flex:1;
    margin:-$cellPadding;
    padding:$cellPadding;
    border:none;
    background: none;
    &:disabled{
        color: #a5a5a5;
    }
}
.admin-select{
    flex:1;
    margin:-$cellPadding;
    padding:$cellPadding;
    border:none;
    background: none;
}
.admin-select-container{
    margin:-$cellPadding;
    margin-right: 0;
    display: flex;
    flex:1;
    align-items: center;
    .admin-select{
        margin:0 10px 0 0;
    }
}
.admin-input-flags{
    list-style: none;
    margin:0;
    padding:0;
    li{
        display: flex;
        input{
            margin-right: 10px;
        }
    }
}

.admin-button{
    padding:10px 20px;
    font-size: 16px;
    border-radius: 10px;
    border-width: 0;
    cursor: pointer;
    &:disabled{
        opacity: 0.5;
    }
}

.admin-toolbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:$cellPadding 0;
    &.admin-toolbar-container{
        margin-left:20px;
        margin-right:20px;
    }
}


.admin-screen{
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    &.admin-screen-busy{
        opacity: 0.3;
    }
}


.admin-prompt-wrapper{
    z-index: 1000;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .admin-prompt-bg{
        background: #ffffff88;
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        z-index: 1;
    }
    .admin-prompt{
        position: relative;
        z-index: 2;
        max-width: 200px;
        margin:30px;
        background: #ffffff;
        border-radius: 20px;
        padding:20px;
        box-shadow: 0px 0px 17px 1px rgba(0,0,0,0.3);
    }
    h2{
        margin-top:5px;
        margin-bottom: 20px;
        font-weight: normal;
        font-size: 16px;
    }
    .admin-prompt-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.admin-prompt-body-row{
            flex-direction: row;
        }
        .admin-button{
            margin-left: 10px;
            &:first-child{
                margin-left: 0;
            }
        }
    }
}

.admin-ul{
    list-style: none;
    margin:0;
    padding:0;
    >li{
        margin: 10px;
        padding: 20px;
        background: $cColor;
        border-radius: 10px;
    }
}

.admin-trail{
    display: flex;
    flex-direction: row;
    color:#888;
    &.admin-trail-container{
        margin-left: 20px;
        margin-right: 20px;
    }
    a{
        margin: 0 20px;
        &:first-child{
            margin-left: 0;
        }
    }
}

.admin-loading{
    padding:30px;
    text-align: center;
    &.admin-loading-slim{
        padding:0;
    }
}

.admin-file-input{
    img{
        height:100px;
    }
    >div{
        margin-bottom: 10px;
    }
}
